<template>
    <div>
        <Header />

        <div class="px-3">
            <div class="text-white py-3">
                <p class="mb-2 fw-bold fs-px-18">Confirm Email</p>
                <p class="fs-px-12">Please enter the verification code sent to</p>
                <p class="fs-px-12 text-yellow-1">{{email}}</p>
            </div>

            <div class="mb-4">
                <p class="text-white py-3 text-center">Enter verification code</p>

                <label class="flex-between-center mb-3 code_box position-relative" for="code_input">
                    <input type="text" class="form-control border-0 fs-5 fw-bold rounded-1 h-px-48 bg-white w-px-48 h-px-48 text-center" v-model="num_1" maxlength="1" readonly>
                    <input type="text" class="form-control border-0 fs-5 fw-bold rounded-1 h-px-48 bg-white w-px-48 h-px-48 text-center" v-model="num_2" maxlength="1" readonly>
                    <input type="text" class="form-control border-0 fs-5 fw-bold rounded-1 h-px-48 bg-white w-px-48 h-px-48 text-center" v-model="num_3" maxlength="1" readonly>
                    <input type="text" class="form-control border-0 fs-5 fw-bold rounded-1 h-px-48 bg-white w-px-48 h-px-48 text-center" v-model="num_4" maxlength="1" readonly>
                    <input type="text" class="form-control border-0 fs-5 fw-bold rounded-1 h-px-48 bg-white w-px-48 h-px-48 text-center" v-model="num_5" maxlength="1" readonly>
                    <input type="text" class="form-control border-0 fs-5 fw-bold rounded-1 h-px-48 bg-white w-px-48 h-px-48 text-center" v-model="num_6" maxlength="1" readonly>

                    <input type="text" id="code_input" class="position-absolute top-0 left-0 w-100 opacity-0" v-model="num_full">
                </label>

                <button class="py-3 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn fw-bold" @click="ConfirmCode()">
                    Confirm Email
                </button>
            </div>
        </div>

    </div>
</template>


<script>
const CryptoJS = require("crypto-js");
import Header from '@/components/common/Header.vue'

export default {
    components:{
        Header
    },
    data(){
        return{
            email: '',

            code: 111111,

            num_full: null,
            num_1: '',
            num_2: '',
            num_3: '',
            num_4: '',
            num_5: '',
            num_6: '',
            number : '',
            code : this.$route.params.code,
            email : '',
            // front ui
        }
    },
    mounted(){
        this.Confirm()
    },
    methods:{
        Confirm(){
            const code =  this.code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mailing/confirm',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.email = body.info.email;

                            // this.$pp({
                            //     msg: 'Email verification was successful.', 
                            //     is_confirm: false, 
                            //     auto_hide:false
                            // })
                            // this.$router.push(`/signup/set-profile/${code}`)
                            // return false;
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'This link has already been used.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            this.$router.push(`/signin`)
                            return false;
                        }
                    }
                }   
            )
        },
        ConfirmCode(){
            const code =  this.code;
            const number = this.num_1+this.num_2+this.num_3+this.num_4+this.num_5+this.num_6
            const body = {code,number};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mailing/confirmCode',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            
                            this.$pp({
                                msg: 'Email verification was successful.', 
                                is_confirm: false, 
                                auto_hide:false
                            })
                            this.$router.push(`/signup/set-profile/${code}`)
                            return false;
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'This link has already been used.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            this.$router.push(`/signin`)
                            return false;
                        }else if(res.data.code =="300"){
                            this.$pp({
                                msg: 'Please check your verification code.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            // this.$router.push(`/signin`)
                            return false;
                        }
                    }
                }   
            )
        },
        SendCodeAgain(){
            this.$pp({
                msg: 'Success! Please check your email', 
                is_confirm: false, 
                auto_hide:true
            })
        },
    },
    watch:{
        num_full(){
            if( this.num_full.length > 6 ){
                this.num_full = this.num_full.substr(0, 6); 
            }else{
                this.num_1 = this.num_full.substr(0, 1); 
                this.num_2 = this.num_full.substr(1, 1); 
                this.num_3 = this.num_full.substr(2, 1); 
                this.num_4 = this.num_full.substr(3, 1); 
                this.num_5 = this.num_full.substr(4, 1); 
                this.num_6 = this.num_full.substr(5, 1); 
            }
        }
    }

}
</script>
<style scoped>
    .code_box input{
        line-height: 48px;
    }
</style>